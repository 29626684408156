import { parseISO } from "date-fns";
import { MaintTypes } from "../types/meld";
import { getFullName } from "./agent-utils";
import { getInviteFullName } from "./invite-utils";
import { getAssignedPropertyGroupsByNumber, getVendorDisplayName } from "./vendor-utils";
export const isAssigned = (assignment) => {
  return !assignment.rejected && !assignment.canceled;
};
export const getAssignedAgents = (meld) => {
  return (meld.in_house_servicers || []).map((servicer) => servicer.agent);
};
export const getOpenVendorAssignment = (meld) => {
  return meld.vendor_assignment_requests.find((assignment) => isAssigned(assignment));
};
export const getAssignedVendor = (meld) => {
  var _a;
  if (((_a = meld.vendor_assignment_requests) == null ? void 0 : _a.length) > 0) {
    let openAssignment = getOpenVendorAssignment(meld);
    if (openAssignment) {
      return openAssignment.vendor;
    }
  }
  return;
};
export const getOpenVendorInviteAssignment = (meld) => {
  var _a;
  return (_a = meld.invite_assignments) == null ? void 0 : _a.find((assignment) => {
    return !assignment.canceled;
  });
};
export const getAssignedInvite = (meld) => {
  let inviteAssignments = meld.invite_assignments;
  if (inviteAssignments && inviteAssignments.length > 0) {
    let openAssignment = getOpenVendorInviteAssignment(meld);
    if (openAssignment) {
      return openAssignment;
    }
  }
  return;
};
export const getAssignedMaint = (meld) => {
  let servicers = getAssignedAgents(meld);
  if (servicers.length > 0) {
    return {
      type: MaintTypes.MANAGEMENT_AGENT,
      in_house_servicers: servicers,
      names: servicers.map((servicer) => getFullName(servicer))
    };
  }
  let vendor = getAssignedVendor(meld);
  if (vendor) {
    return {
      type: MaintTypes.VENDOR,
      vendor,
      name: getVendorDisplayName(vendor)
    };
  }
  let inviteAssignment = getAssignedInvite(meld);
  if (inviteAssignment) {
    return {
      type: MaintTypes.INVITED_VENDOR,
      invite_assignment: inviteAssignment,
      name: getInviteFullName(inviteAssignment.invite_group.invite)
    };
  }
  return;
};
export const isMaintenanceVendor = (maint) => maint.composite_id.startsWith("1");
export const isMaintenanceManagementAgent = (maint) => maint.composite_id.startsWith("2");
export const isMaintenanceVendorInvite = (maint) => maint.composite_id.startsWith("3");
export const getFilterByPropertyGroups = (property_groups) => {
  const validPropertyGroupsMap = {};
  for (let pg of property_groups) {
    validPropertyGroupsMap[pg] = true;
  }
  return (maint) => {
    switch (maint.type) {
      case MaintTypes.MANAGEMENT_AGENT:
        return !property_groups.length || maint.property_groups.some((pg) => validPropertyGroupsMap[pg]);
      case MaintTypes.VENDOR:
        return !property_groups.length || getAssignedPropertyGroupsByNumber(property_groups, maint.excluded_property_groups).some(
          (pg) => validPropertyGroupsMap[pg]
        );
      case MaintTypes.INVITED_VENDOR:
        return true;
      default:
        return false;
    }
  };
};
export const isWorkersCompValid = (selectedMaintenance) => {
  const today = /* @__PURE__ */ new Date();
  today.setHours(0, 0, 0, 0);
  const workersCompensationExpiration = selectedMaintenance.workers_compensation_expiration ? parseISO(selectedMaintenance.workers_compensation_expiration) : null;
  return !workersCompensationExpiration || workersCompensationExpiration >= today;
};
export const isLiabilityInsuranceValid = (selectedMaintenance) => {
  const today = /* @__PURE__ */ new Date();
  today.setHours(0, 0, 0, 0);
  const generalLiabilityInsuranceExpiration = selectedMaintenance.general_liability_insurance_expiration ? parseISO(selectedMaintenance.general_liability_insurance_expiration) : null;
  return !generalLiabilityInsuranceExpiration || generalLiabilityInsuranceExpiration >= today;
};
export const isAppfolioComplianceValid = (selectedMaintenance) => {
  return selectedMaintenance.vendor_managements[0].appfolio_compliance;
};
