export const RouteUrls = {
  // an old UI page
  accountSettings: "/account-settings/user/",
  chooseAccount: "/choose-account/",
  dashboard: `/dashboard/`,
  // an old UI page - currently same url as any meld detail
  estimateDetails: ({ id }, parentMeldId) => `/meld/${id}/summary/?parentMeldID=${parentMeldId}`,
  expenditureDetails: ({ id }) => `/melds/expenditures/${id}/summary/`,
  expenditureList: `/melds/expenditures/`,
  feedback: `/feedback/`,
  insightsOverview: `/insights/overview/`,
  integrations: `/integrations/`,
  invoiceDetails: ({ id }) => `/melds/payments/${id}/summary/`,
  invoiceList: `/melds/payments/`,
  // an old UI page
  meldCalendarList: `/calendar/melds/`,
  // an old UI page
  meldCalendar: ({ id }) => `/calendar/melds/${id}/`,
  // an old UI page
  meldCalendarBook: ({ id }) => `/calendar/melds/${id}/book/`,
  meldCalendarMeldAvailabilities: ({ id }) => `calendar/melds/${id}/availability/`,
  // an old UI page
  meldClone: ({ id }) => `/meld/${id}/clone/`,
  meldDetails: ({ id }) => `/meld/${id}/summary/`,
  meldDetailsActivity: ({ id }) => `/meld/${id}/activity/`,
  meldDetailsChat: ({ id }) => `/meld/${id}/messages/`,
  meldDetailsWorkLog: ({ id }) => `/meld/${id}/log/`,
  meldDetailsContacts: ({ id }) => `/meld/${id}/contacts/`,
  meldDetailsRelated: ({ id }) => `/meld/${id}/related/`,
  meldDetailsReminders: ({ id }) => `/meld/${id}/reminders/`,
  meldDetailsRemindersAdd: ({ id }) => `/meld/${id}/add-reminder/`,
  meldDetailsRemindersEdit: ({ id: meldId }, reminderId) => `/meld/${meldId}/reminders/${reminderId}/edit`,
  meldDetailsEstimates: ({ id }) => `/meld/${id}/estimates/`,
  // an old UI page
  meldEstimates: ({ id }) => `/meld/${id}/estimates/`,
  // an old UI page
  meldEdit: ({ id }) => `/meld/${id}/edit/`,
  // an old UI page
  meldEstimatesDetailView: (estimate_meld, original_meld) => `/meld/${estimate_meld}/summary/?parentMeldID=${original_meld}`,
  // an old UI page
  meldEstimatesLineItemsView: (estimate_meld, original_meld) => `/meld/${estimate_meld}/estimate-details/?parentMeldID=${original_meld}`,
  // an old UI page
  meldExpenditures: (expenditure_id) => `/melds/expenditures/${expenditure_id}/`,
  // an old UI page
  meldExpendituresCreate: ({ id }) => `/meld/${id}/expenditures/`,
  meldList: `/melds/melding/`,
  // an old UI page
  meldOwnerApproval: ({ id }) => `/meld/${id}/owner-approval/`,
  // an old UI page
  meldOwnerApprovalSummary: ({ id }) => `/meld/${id}/owner-approval-summary/`,
  // an old UI page
  meldPrint: ({ id }) => `/meld/${id}/print/`,
  // an old UI page
  meldCreateNewProject: `/projects/new/`,
  // an old UI page
  meldManagementAppt: ({ id }) => `/management-appointment/${id}/`,
  // an old UI page
  meldVendorAppt: ({ id }) => `/vendor-appointment/${id}/`,
  meldProjectList: `/projects/`,
  // an old UI page
  meldProject: ({ id }) => `/projects/${id}/summary/`,
  // an old UI page
  meldUploadInvoice: ({ id }) => `/meld/${id}/invoice/`,
  // an old UI page
  newMeld: `/melds/new-meld/`,
  // an old UI page
  newOwner: `/properties/owners/new/`,
  // an old UI page
  newProperty: `/properties/addresses/new/`,
  // an old UI page
  newResident: `/tenants/new/`,
  // an old UI page
  newUnit: `/properties/new/`,
  nexus: {
    nexusRequestsList: `/nexus/requests/`,
    nexusAPIKeysList: `/nexus/api-keys/`,
    nexusSettings: `/nexus/settings/`
  },
  // an old UI page
  notifications: `/notifications/`,
  ownerDetail: (id) => `/properties/owners/${id}/summary/`,
  ownerDetailEdit: (id) => `/properties/owners/${id}/edit/`,
  ownerList: `/properties/owners/`,
  propertyCarePlusList: `/owner-services/`,
  propertyCarePlusDetails: (pcpId) => `/owner-services/${pcpId}/`,
  propertyCarePlusDetailsEdit: (pcpId) => `/owner-services/${pcpId}/edit`,
  propertyDetail: ({ id }) => `/properties/addresses/${id}/summary/`,
  propertyDetailEdit: ({ id }) => `/properties/addresses/${id}/edit/`,
  propertyGroupDetail: ({ id }) => `/properties/groups/${id}/addresses/`,
  propertyGroupList: `/properties/groups/`,
  propertyList: `/properties/addresses/`,
  recurringMeldsList: `/melds/recurring/`,
  recurringMeldDetail: (id) => `/melds/recurring/${id}/`,
  // an old UI page
  reporting: "/reporting/",
  residentList: `/tenants/`,
  residentListInvited: `/tenants/invited/`,
  residentListRequests: `/tenants/requests/`,
  residentDetail: ({ id: residentId }) => `/tenants/${residentId}/`,
  residentDetailEdit: ({ id: residentId }) => `/tenants/${residentId}/edit/`,
  // an old UI page whose data is now on the main resident details page
  residentDetailMelds: ({ id: residentId }) => `/tenants/${residentId}/meld/`,
  // an old UI page
  trainingVideos: `/users/videos/`,
  unitDetail: ({ id }) => `/properties/${id}/summary/`,
  unitDetailEdit: ({ id }) => `/properties/${id}/edit/`,
  unitDetailMelds: ({ id }) => `/properties/${id}/melds/`,
  unitDetailInvoices: ({ id }) => `/properties/${id}/invoices/`,
  unitDetailResidents: ({ id }) => `/properties/${id}/tenants/`,
  unitDetailContacts: ({ id }) => `/properties/${id}/contacts/`,
  unitList: `/properties/`,
  userManagement: `/users/`,
  vendorList: `/vendors/`,
  vendorDetail: (id) => `vendors/${id}/summary/`,
  vendorListPending: `/vendors/pending/`,
  vendorSearchList: `/vendors/search/`,
  vendorSearchDetail: (id) => `vendors/search/${id}/`,
  workflowList: `/workflows/list/`
};
export const meldListDefaultQuery = { saved_filter: "default" };
export const projectListDefaultQuery = { completed: "false", order_by: "due_date" };
export const invoiceListDefaultQuery = { saved_filter: "default" };
export const residentListDefaultQuery = { ordering: ["last_name", "first_name"] };
export const residentListInvitedDefaultQuery = { ordering: "name" };
