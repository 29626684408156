import URL from "@pm-shared/utils/url";
import { css } from "@emotion/react";
export const colors = {
  neutrals: {
    gray50: "#FCFCFC",
    gray100: "#F7F9FA",
    gray200: "#EBEFF2",
    gray300: "#DEE2E5",
    gray400: "#CCD3D9",
    gray500: "#AEB6BD",
    gray600: "#6B757D",
    gray700: "#495057",
    gray800: "#343A40",
    gray900: "#0C0D0D"
  },
  brand: {
    meldBlue: "#1175CC",
    lightBlue: "#F8FBFD",
    veryLightBlue: "#E6F2FF",
    darkHover: "#0B4980",
    white: "#FFFFFF"
  },
  interface: {
    red: {
      default: "#B2250F",
      light: "#FDF2F0",
      dark: "#801A0B"
    },
    green: {
      default: "#006B56",
      light: "#F7FCFB",
      dark: "#004D3E",
      statusText: "#16A085"
    },
    yellow: {
      default: "#FFCE70",
      light: "#FDF6E9",
      dark: "#8F681D"
    }
  }
};
export const badgeColors = {
  dark: {
    orange: {
      bg: "#E5A688",
      text: "#592912"
    },
    yellow: {
      bg: "#E5D188",
      text: "#594A12"
    },
    lightGreen: {
      bg: "#D7E588",
      text: "#4F5912"
    },
    mint: {
      bg: "#88E5C9",
      text: "#125944"
    },
    green: {
      bg: "#88E5B5",
      text: "#125934"
    },
    softBlue: {
      bg: "#88D7E5",
      text: "#124E59"
    },
    softPurple: {
      bg: "#A688E5",
      text: "#281259"
    },
    violet: {
      bg: "#A688E5",
      text: "#281259"
    },
    red: {
      bg: "#E58888",
      text: "#591212"
    },
    salmon: {
      bg: "#E588A5",
      text: "#591228"
    },
    pink: {
      bg: "#E388E5",
      text: "#581259"
    }
  },
  light: {
    orange: {
      bg: "#F2D2C3",
      text: "#592912"
    },
    yellow: {
      bg: "#F2E8C3",
      text: "#594A12"
    },
    lightGreen: {
      bg: "#EBF2C3",
      text: "#4F5912"
    },
    mint: {
      bg: "#C3F2E4",
      text: "#125944"
    },
    green: {
      bg: "#C3F2DA",
      text: "#125934"
    },
    softBlue: {
      bg: "#C3EBF2",
      text: "#124E59"
    },
    softPurple: {
      bg: "#C3D4F2",
      text: "#281259"
    },
    violet: {
      bg: "#D2C3F2",
      text: "#281259"
    },
    red: {
      bg: "#F2C3C3",
      text: "#591212"
    },
    salmon: {
      bg: "#F2C3D2",
      text: "#591228"
    },
    pink: {
      bg: "#F1C3F2",
      text: "#581259"
    },
    gray: {
      bg: "#EBEFF2",
      text: "#495057"
    }
  }
};
export const GenericStatusBadgeProps = {
  approved: {
    bgColor: badgeColors.light.green.bg,
    textColor: badgeColors.light.green.text
  },
  pending: {
    bgColor: colors.interface.yellow.light,
    textColor: colors.interface.yellow.dark
  },
  rejected: {
    bgColor: badgeColors.light.red.bg,
    textColor: badgeColors.light.red.text
  },
  neutral: {
    bgColor: colors.neutrals.gray300,
    textColor: colors.neutrals.gray800
  },
  purple: {
    bgColor: badgeColors.light.softPurple.bg,
    textColor: badgeColors.light.softPurple.text
  }
};
export const PageContentMaxWidth = "1800px";
export const PageContentHeight = `calc(100vh - 64px)`;
export const InnerPageContentHeight = `calc(100vh - 96px)`;
export const MobileInnerPageContentHeight = `calc(100vh - 80px)`;
export const DetailPageSectionGap = "48px";
export const BorderColor = colors.neutrals.gray400;
const ShortIconMap = {
  property: "icons/apartment.svg"
};
export const IconMap = {
  full: {
    property: URL.getStatic(ShortIconMap.property)
  },
  short: ShortIconMap
};
export const fontSizes = {
  p1: "16px",
  p2: "14px",
  p3: "12px"
};
export const fontWeights = {
  regular: 400,
  semiBold: 600,
  bold: 700
};
export const nativeDateInputStyles = {
  padding: "6px",
  color: colors.neutrals.gray800,
  borderRadius: "6px",
  border: `1px solid ${colors.neutrals.gray200}`,
  width: "100%"
};
export const mobileDatePickerCSS = css`
  & {
    display: flex;
    flex-grow: 1;
  }

  & > input.mobile-date-input {
    width: 100%;
  }
`;
