export const MeldStatus = {
  OPEN: "OPEN",
  PENDING_ASSIGNMENT: "PENDING_ASSIGNMENT",
  PENDING_VENDOR: "PENDING_VENDOR",
  PENDING_TENANT_AVAILABILITY: "PENDING_TENANT_AVAILABILITY",
  PENDING_MORE_VENDOR_AVAILABILITY: "PENDING_MORE_VENDOR_AVAILABILITY",
  PENDING_MORE_MANAGEMENT_AVAILABILITY: "PENDING_MORE_MANAGEMENT_AVAILABILITY",
  PENDING_COMPLETION: "PENDING_COMPLETION",
  VENDOR_COULD_NOT_COMPLETE: "VENDOR_COULD_NOT_COMPLETE",
  MAINTENANCE_COULD_NOT_COMPLETE: "MAINTENANCE_COULD_NOT_COMPLETE",
  MANAGER_CANCELED: "MANAGER_CANCELED",
  TENANT_CANCELED: "TENANT_CANCELED",
  PENDING_ESTIMATES: "PENDING_ESTIMATES",
  COMPLETED: "COMPLETED"
};
export const OpenStatuses = [
  MeldStatus.PENDING_ESTIMATES,
  MeldStatus.PENDING_TENANT_AVAILABILITY,
  MeldStatus.PENDING_MORE_VENDOR_AVAILABILITY,
  MeldStatus.PENDING_MORE_MANAGEMENT_AVAILABILITY,
  MeldStatus.PENDING_COMPLETION,
  MeldStatus.PENDING_VENDOR,
  MeldStatus.PENDING_ASSIGNMENT
];
export const SchedulableStatuses = {
  ScheduleStatuses: [
    MeldStatus.OPEN,
    MeldStatus.PENDING_TENANT_AVAILABILITY,
    MeldStatus.PENDING_MORE_VENDOR_AVAILABILITY,
    MeldStatus.PENDING_MORE_MANAGEMENT_AVAILABILITY,
    MeldStatus.PENDING_COMPLETION
  ],
  RescheduleStatuses: [MeldStatus.PENDING_COMPLETION]
};
export const ClosedStatuses = {
  CancelStatuses: [MeldStatus.TENANT_CANCELED, MeldStatus.MANAGER_CANCELED],
  CouldNotCompleteStatuses: [MeldStatus.MAINTENANCE_COULD_NOT_COMPLETE, MeldStatus.VENDOR_COULD_NOT_COMPLETE],
  CompletedStatuses: [MeldStatus.COMPLETED]
};
export const MeldManagerStatusLabels = {
  OPEN: "Open",
  PENDING_ASSIGNMENT: "Pending Assignment",
  PENDING_VENDOR: "Pending Vendor Acceptance",
  PENDING_TENANT_AVAILABILITY: "Pending Resident Availability",
  PENDING_MORE_VENDOR_AVAILABILITY: "More Availability Requested",
  PENDING_MORE_MANAGEMENT_AVAILABILITY: "More Availability Requested",
  PENDING_COMPLETION: "In Progress",
  VENDOR_COULD_NOT_COMPLETE: "Vendor Could Not Complete",
  MAINTENANCE_COULD_NOT_COMPLETE: "Maintenance Could Not Complete",
  MANAGER_CANCELED: "Manager Canceled",
  TENANT_CANCELED: "Resident Canceled",
  PENDING_ESTIMATES: "Pending Estimates",
  COMPLETED: "Completed"
};
export const MaintTypes = {
  VENDOR: "Vendor",
  MANAGEMENT_AGENT: "ManagementAgent",
  MANAGEMENT: "Management",
  VENDOR_PREFERENCE_LIST: "VendorPreferenceList",
  INVITED_VENDOR: "VendorInvite"
};
export const MaintTypesDisplayNames = {
  [MaintTypes.VENDOR]: "Vendor",
  [MaintTypes.MANAGEMENT_AGENT]: "Internal",
  [MaintTypes.MANAGEMENT]: "Management",
  [MaintTypes.VENDOR_PREFERENCE_LIST]: "Vendor Preference List",
  [MaintTypes.INVITED_VENDOR]: "Unregistered Vendor"
};
export const MeldPriority = {
  LOW: "LOW",
  MEDIUM: "MEDIUM",
  HIGH: "HIGH"
};
export const MeldPriorityReadable = {
  LOW: "Low",
  MEDIUM: "Medium",
  HIGH: "High"
};
export const ResidentWorkCategories = {
  APPLIANCES: "Appliances",
  BLINDS_WINDOW_TREATMENTS: "Blinds/Window treatments",
  CARPENTRY: "Carpentry",
  CIRCUIT_BREAKER: "Circuit Breaker",
  CLEANING: "Cleaning",
  DOORS: "Doors",
  DRIVEWAY: "Driveway",
  DRYWALL: "Drywall",
  ELECTRICAL: "Electrical",
  EXTERIOR: "Exterior",
  FIREPLACE: "Fireplace",
  FLOORING: "Flooring",
  GARAGE_DOOR: "Garage Door",
  GARBAGE_DISPOSAL: "Garbage Disposal",
  GENERAL: "General",
  HARDWARE: "Hardware",
  HVAC: "Heating/AC",
  INTERIOR: "Interior",
  LANDSCAPING: "Landscaping",
  LOCKS: "Locks",
  OTHER_WORK_CATEGORY: "Other",
  OUTSIDE_WATER_SPIGOT: "Outside Water Spigot",
  PAINTING: "Painting",
  PEST_CONTROL: "Pest control",
  PLUMBING: "Plumbing",
  POOL: "Pool",
  ROOFING: "Roofing",
  SEWER: "Sewer",
  SHOWER: "Shower",
  SIDING: "Siding",
  SMOKE_DETECTOR_CO_DETECTORS: "Smoke Detector/CO detectors",
  SOFFIT_FASCIA: "Soffit/Fascia",
  STAIRS: "Stairs",
  TOILET: "Toilet",
  TOWEL_BARS: "Towel bars",
  WASHER_DRYER: "Washer/Dryer",
  WATER_DAMAGE: "Water Damage",
  WATER_HEATER: "Water Heater",
  WATER_SOFTENER: "Water Softener",
  WINDOWS: "Windows"
};
