export const INTEGRATIONS = {
  APPFOLIO: "AppFolio",
  BUILDIUM: "Buildium",
  PROPERTYWARE: "Propertyware",
  RENTMANAGER: "Rent Manager"
};
export const RentManagerModelSyncStatus = {
  LINK: "LINK",
  IMPORT: "IMPORT",
  NOT_SYNCED: "NOT_SYNCED"
};
