import { ApiUrls } from "@pm-frontend/shared/utils/api-urls";
import { apiFetch } from "@pm-frontend/shared/utils/apiFetch";
import { LinkHelper } from "@pm-shared/utils/link";
import { useQuery } from "@tanstack/react-query";
const AllMaintenanceKeys = {
  all: ["all-maintenance"]
};
const useGetAllMaintenance = ({ registeredOnly, refetchOnMount } = {
  registeredOnly: false,
  refetchOnMount: true
}) => {
  return useQuery({
    queryFn: () => apiFetch(LinkHelper.normalize(ApiUrls.maintenanceList) + `?registeredOnly=${registeredOnly}`),
    queryKey: AllMaintenanceKeys.all,
    cacheTime: Infinity,
    refetchOnMount
  });
};
export { useGetAllMaintenance };
