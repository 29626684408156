import { LinkHelper } from "@pm-shared/utils/link";
import { RouteUrls } from "./route-urls";
export function getFullAddressWithPropertyNameArray(prop) {
  return [
    getPropertyNameDistinctFromLine1(prop),
    prop.line_1,
    prop.line_2,
    prop.line_3,
    getFormattedCityStateZip(prop)
  ].filter(Boolean);
}
export function getFullAddressWithoutPropertyName(prop) {
  return [prop.line_1, prop.line_2, prop.line_3, getFormattedCityStateZip(prop)].filter(Boolean).join(", ");
}
export function getPropertyNameDistinctFromLine1(prop) {
  if (prop.property_name === prop.line_1) {
    return "";
  }
  return prop.property_name || "";
}
export function getTwoLinePropertyNameAddress(prop) {
  return [getPropertyNameDistinctFromLine1(prop), getFullAddressWithoutPropertyName(prop)].filter(Boolean).join("\n");
}
export const getOneLinePropertyNameAddress = (prop, includePropertyName = true) => {
  const base = [prop.line_1, prop.city, prop.county_province].filter(Boolean).join(", ");
  if (includePropertyName) {
    let propertyName = getPropertyNameDistinctFromLine1(prop);
    if (propertyName) {
      return propertyName + ", " + base;
    }
  }
  return base;
};
export function getTwoOrThreeLinePropertyNameAddress(prop) {
  const addressLine = [prop.line_1, prop.line_2, prop.line_3].filter(Boolean).join(", ");
  return [getPropertyNameDistinctFromLine1(prop), addressLine, getFormattedCityStateZip(prop)].filter(Boolean).join("\n");
}
export function getFormattedCityStateZip(prop) {
  if (prop.city) {
    return `${prop.city}, ${prop.county_province} ${prop.postcode}`;
  }
  return `${prop.county_province} ${prop.postcode}`;
}
export const getPropertyDetailsLink = (propertyId, tab) => {
  if (tab === "edit") {
    return LinkHelper.normalize(RouteUrls.propertyDetailEdit({ id: propertyId }));
  }
  return LinkHelper.normalize(RouteUrls.propertyDetail({ id: propertyId }));
};
