export const InvoiceStatus = {
  DRAFT: "DRAFT",
  PAID: "PAID",
  SUBMITTED: "SUBMITTED",
  IN_REVIEW: "IN_REVIEW",
  HOLD: "HOLD",
  DECLINED: "DECLINED",
  APPROVED: "APPROVED"
};
export const InvoiceStatusLabel = {
  DRAFT: "Draft",
  PAID: "Paid",
  SUBMITTED: "Submitted",
  IN_REVIEW: "In Review",
  HOLD: "Hold",
  DECLINED: "Declined",
  APPROVED: "Approved"
};
export const ExpenditureStatusActions = {
  DRAFT: "Place in draft",
  IN_REVIEW: "Place in review",
  HOLD: "Place on hold",
  APPROVED: "Approve",
  BILLED: "Mark billed"
};
export const ExpenditureStatusLabel = {
  DRAFT: "Draft",
  IN_REVIEW: "In Review",
  HOLD: "On Hold",
  APPROVED: "Approved",
  BILLED: "Billed"
};
export const PriorityStatus = {
  LOW: "Low Priority",
  MEDIUM: "Medium Priority",
  HIGH: "High Priority"
};
export const OwnerApproval = {
  OWNER_APPROVAL_NOT_REQUESTED: "OWNER_APPROVAL_NOT_REQUESTED",
  OWNER_APPROVAL_REQUESTED: "OWNER_APPROVAL_REQUESTED",
  OWNER_APPROVAL_APPROVED: "OWNER_APPROVAL_APPROVED",
  OWNER_APPROVAL_NOT_APPROVED: "OWNER_APPROVAL_NOT_APPROVED",
  OWNER_APPROVAL_REQUESTED_ESTIMATES: "OWNER_APPROVAL_REQUESTED_ESTIMATES",
  OWNER_APPROVAL_REQUESTED_FOR_PARENT_MELD: "OWNER_APPROVAL_REQUESTED_FOR_PARENT_MELD"
};
export const WorkCategory = {
  APPLIANCES: "APPLIANCES",
  BLINDS_WINDOW_TREATMENTS: "BLINDS_WINDOW_TREATMENTS",
  CARPENTRY: "CARPENTRY",
  CIRCUIT_BREAKER: "CIRCUIT_BREAKER",
  CLEANING: "CLEANING",
  DOORS: "DOORS",
  DRIVEWAY: "DRIVEWAY",
  DRYWALL: "DRYWALL",
  ELECTRICAL: "ELECTRICAL",
  EXTERIOR: "EXTERIOR",
  FIREPLACE: "FIREPLACE",
  FLOORING: "FLOORING",
  FOR_RENT_SIGN: "FOR_RENT_SIGN",
  GARAGE_DOOR: "GARAGE_DOOR",
  GARBAGE_DISPOSAL: "GARBAGE_DISPOSAL",
  GENERAL: "GENERAL",
  HARDWARE: "HARDWARE",
  HVAC: "HVAC",
  EVALUATION: "EVALUATION",
  INTERIOR: "INTERIOR",
  LANDSCAPING: "LANDSCAPING",
  LOCKS: "LOCKS",
  OTHER_WORK_CATEGORY: "OTHER_WORK_CATEGORY",
  OUTSIDE_WATER_SPIGOT: "OUTSIDE_WATER_SPIGOT",
  PAINTING: "PAINTING",
  PEST_CONTROL: "PEST_CONTROL",
  PLUMBING: "PLUMBING",
  POOL: "POOL",
  ROOFING: "ROOFING",
  SEWER: "SEWER",
  SHOWER: "SHOWER",
  SIDING: "SIDING",
  SMOKE_DETECTOR_CO_DETECTORS: "SMOKE_DETECTOR_CO_DETECTORS",
  SOFFIT_FASCIA: "SOFFIT_FASCIA",
  STAIRS: "STAIRS",
  TOILET: "TOILET",
  TOWEL_BARS: "TOWEL_BARS",
  TURNOVER: "TURNOVER",
  VIOLATIONS: "VIOLATIONS",
  WASHER_DRYER: "WASHER_DRYER",
  WATER_HEATER: "WATER_HEATER",
  WATER_DAMAGE: "WATER_DAMAGE",
  WATER_SOFTENER: "WATER_SOFTENER",
  WINDOWS: "WINDOWS"
};
export const WorkCategoryLabel = {
  APPLIANCES: "Appliances",
  BLINDS_WINDOW_TREATMENTS: "Blinds/Window Treatments",
  CARPENTRY: "Carpentry",
  CIRCUIT_BREAKER: "Circuit Breaker",
  CLEANING: "Cleaning",
  DOORS: "Doors",
  DRIVEWAY: "Driveway",
  DRYWALL: "Drywall",
  ELECTRICAL: "Electrical",
  EXTERIOR: "Exterior",
  FIREPLACE: "Fireplace",
  FLOORING: "Flooring",
  FOR_RENT_SIGN: "For Rent Sign",
  GARAGE_DOOR: "Garage Door",
  GARBAGE_DISPOSAL: "Garbage Disposal",
  GENERAL: "General",
  HARDWARE: "Hardware",
  HVAC: "Heating/AC",
  EVALUATION: "Evaluation",
  INTERIOR: "Interior",
  LANDSCAPING: "Landscaping",
  LOCKS: "Locks",
  OTHER_WORK_CATEGORY: "Other",
  OUTSIDE_WATER_SPIGOT: "Outside Water Spigot",
  PAINTING: "Painting",
  PEST_CONTROL: "Pest Control",
  PLUMBING: "Plumbing",
  POOL: "Pool",
  ROOFING: "Roofing",
  SEWER: "Sewer",
  SHOWER: "Shower",
  SIDING: "Siding",
  SMOKE_DETECTOR_CO_DETECTORS: "Smoke Detector/CO Detectors",
  SOFFIT_FASCIA: "Soffit/Fascia",
  STAIRS: "Stairs",
  TOILET: "Toilet",
  TOWEL_BARS: "Towel Bars",
  TURNOVER: "Turnover",
  VIOLATIONS: "Violations",
  WASHER_DRYER: "Washer/Dryer",
  WATER_HEATER: "Water Heater",
  WATER_DAMAGE: "Water Damage",
  WATER_SOFTENER: "Water Softener",
  WINDOWS: "Windows"
};
export const InvoiceExportStatus = {
  EXPORTED: "EXPORTED",
  FAILED: "FAILED"
};
export const InvoiceExportStatusLabel = {
  EXPORTED: "Exported",
  FAILED: "Not Exported"
};
