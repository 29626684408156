var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { ApiUrls } from "@pm-frontend/shared/utils/api-urls";
import { LinkHelper } from "@pm-frontend/shared/utils/api-helpers";
import { apiFetch, apiPatch, apiPost } from "@pm-frontend/shared/utils/apiFetch";
import { toastMessages, useAddToast } from "@pm-frontend/shared/store/toast";
import { meldKeys } from "../Melds/queries";
import { isMeldScheduled } from "@pm-frontend/shared/utils/meld-utils";
import { getAssignedMaint } from "@pm-frontend/shared/utils/assignment-utils";
import { useGetAllMaintenance } from "@pm-frontend/shared/hooks/queries/useGetAllMaintenance";
import Features from "@pm-assets/js/common/feature-flags";
const calendarMeldKeys = {
  all: () => [...meldKeys.all, "calendar-melds"],
  meldsListAll: () => [...calendarMeldKeys.all(), "saved_filter"],
  meldsList: (savedFilter) => [
    ...calendarMeldKeys.meldsListAll(),
    "saved_filter",
    savedFilter || "none"
  ],
  meldsAssignedList: (agentId, previousMeldID) => [
    ...calendarMeldKeys.all(),
    "recommended_assigned",
    "agent",
    agentId,
    "previousMeld",
    previousMeldID
  ],
  meldDetail: (id) => [...calendarMeldKeys.all(), "meld", id],
  events: () => [...calendarMeldKeys.all(), "events"],
  managerScheduledEventDetails: (eventId) => [...calendarMeldKeys.events(), "event_details", eventId],
  managerScheduledEvents: (start, timeframe) => [
    ...calendarMeldKeys.events(),
    "manager",
    "start",
    start.toString(),
    "timeframe",
    timeframe.toString()
  ],
  vendorScheduledEvents: (start, timeframe) => [
    ...calendarMeldKeys.events(),
    "vendor",
    "start",
    start.toString(),
    "timeframe",
    timeframe.toString()
  ],
  googleCalendarEventsList: (agentIds, start, timeFrame) => [
    ...calendarMeldKeys.all(),
    "google_calendar_events",
    "agent",
    agentIds,
    "start",
    start.toString(),
    "timeFrame",
    timeFrame.toString()
  ],
  mutations: {
    scheduleMutation: (meldId) => [...calendarMeldKeys.events(), "scheduling", meldId],
    bookSegment: (meldId) => [...calendarMeldKeys.events(), "book", meldId],
    assignAndSchedule: (id) => [...calendarMeldKeys.meldDetail(id), "assignAndSchedule"]
  }
};
const fetchCalendarMelds = (nextPage, initialUrl) => {
  return apiFetch(nextPage || LinkHelper.normalize(initialUrl));
};
const useGetCalendarMeldsList = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const savedFilter = searchParams.get("saved_filter");
  const url = savedFilter ? `${ApiUrls.calendarMelds}?saved_filter=${savedFilter}` : ApiUrls.calendarMelds;
  return useInfiniteQuery({
    queryKey: calendarMeldKeys.meldsList(savedFilter),
    // pageParam is the full url of the next set of results
    queryFn: ({ pageParam }) => fetchCalendarMelds(pageParam, url),
    getNextPageParam: (lastPage) => lastPage.next,
    keepPreviousData: true,
    // prevents unnecessary refetching of current results when view more is clicked
    staleTime: 3e3
  });
};
const useGetUnscheduledMelds = (previousMeldID, agentId) => {
  const pendingAvailStatus = "PENDING_MORE_MANAGEMENT_AVAILABILITY";
  const pendingAssignStatus = "PENDING_ASSIGNMENT";
  const baseURL = `${ApiUrls.calendarRecommendMelds}?status%5B%5D=${pendingAvailStatus}&status%5B%5D=${pendingAssignStatus}`;
  const prevFilter = previousMeldID ? `&prev=${previousMeldID}` : "";
  const url = `${baseURL}${prevFilter}`;
  return useInfiniteQuery({
    queryKey: calendarMeldKeys.meldsAssignedList(agentId.toString(), (previousMeldID == null ? void 0 : previousMeldID.toString()) || "all"),
    queryFn: ({ pageParam }) => fetchCalendarMelds(pageParam, url),
    getNextPageParam: (lastPage) => lastPage.next,
    keepPreviousData: true,
    staleTime: 5e3
  });
};
const useGetCalendarMeldDetails = (meldId) => {
  return useQuery({
    queryKey: calendarMeldKeys.meldDetail((meldId == null ? void 0 : meldId.toString()) || "none"),
    queryFn: () => apiFetch(LinkHelper.normalize(ApiUrls.calendarMeldDetail(meldId))),
    enabled: !!meldId
  });
};
const useGetCalendarManagerEventDetails = (eventId) => {
  return useQuery({
    queryKey: calendarMeldKeys.managerScheduledEventDetails((eventId == null ? void 0 : eventId.toString()) || "none"),
    queryFn: () => apiFetch(LinkHelper.normalize(ApiUrls.managementEventDetail(eventId == null ? void 0 : eventId.toString()))),
    enabled: !!eventId
  });
};
const useGetManagementScheduledEvents = (startTime, selectedTimeFrame) => {
  const searchParam = new URLSearchParams();
  searchParam.set("dtstart__gte", moment(startTime).startOf("day").toISOString());
  searchParam.set("dtend__lt", moment(startTime).startOf("day").add(selectedTimeFrame, "days").toISOString());
  return useQuery({
    queryKey: calendarMeldKeys.managerScheduledEvents(startTime, selectedTimeFrame),
    queryFn: () => apiFetch(`${LinkHelper.normalize(ApiUrls.managementEventList)}?${searchParam.toString()}`)
  });
};
const useGetVendorScheduledEvents = (startTime, selectedTimeFrame) => {
  const searchParam = new URLSearchParams();
  searchParam.set("dtstart__gte", moment(startTime).toISOString());
  searchParam.set("dtend__lt", moment(startTime).add(selectedTimeFrame, "days").toISOString());
  return useQuery({
    queryKey: calendarMeldKeys.vendorScheduledEvents(startTime, selectedTimeFrame),
    queryFn: () => apiFetch(`${LinkHelper.normalize(ApiUrls.vendorEventList)}?${searchParam.toString()}`)
  });
};
const useGetManagementGoogleCalendarEvents = (agentIds, start, selectedTimeFrame) => {
  const searchParam = new URLSearchParams();
  searchParam.set("start__gte", moment(start).toISOString());
  searchParam.set("end__lt", moment(start).add(selectedTimeFrame, "days").toISOString());
  return useQuery({
    queryKey: calendarMeldKeys.googleCalendarEventsList(agentIds, start, selectedTimeFrame),
    queryFn: () => apiFetch(`${LinkHelper.normalize(ApiUrls.googleCalendarApi.googleCalendarEventsList)}?${searchParam.toString()}`),
    enabled: Features.isGoogleCalendarReadIntegrationEnabled()
  });
};
const formatEventDataToPatch = (startTime, durationInMin, assignees, type, events = null, isAddingAppointment = false, isReschedulingAppointment = false, appointmentId) => {
  const dtend = startTime.clone().add(durationInMin, "minutes").toISOString();
  const dtstart = startTime.toISOString();
  if (assignees.length === 0) {
    return null;
  }
  if (assignees.every((a) => a.type === "ManagementAgent")) {
    if (isReschedulingAppointment) {
      return {
        appointment: appointmentId,
        new_segments: [
          {
            event: {
              dtend,
              dtstart
            }
          }
        ]
      };
    } else if (isAddingAppointment) {
      return {
        new_segments: [
          {
            event: {
              dtend,
              dtstart
            }
          }
        ]
      };
    } else if (type === "accept") {
      if (!Features.isMultipleAppointmentsEnabled()) {
        return {
          mark_scheduled: true,
          segments_to_keep: [],
          management_availability_segments: [
            {
              event: {
                dtend,
                dtstart,
                managementavailabilitysegment: {
                  meld: {
                    in_house_servicers: assignees.map((a) => ({ agent: a }))
                  }
                }
              }
            }
          ]
        };
      } else if (Features.isMultipleAppointmentsEnabled() && events && events.length > 0) {
        return {
          mark_scheduled: true,
          segments_to_keep: [],
          management_availability_segments: [
            {
              event: {
                dtend,
                dtstart,
                managementavailabilitysegment: {
                  meld: {
                    in_house_servicers: assignees.map((a) => ({ agent: a }))
                  }
                }
              }
            }
          ],
          multiple_segments_to_book: events.filter((event) => event.startTime).map((event) => ({
            event: {
              dtend: event.startTime.clone().add(event.endTime && event.endTime.diff(event.startTime, "minutes") || 0, "minutes").toISOString(),
              dtstart: event.startTime.toISOString()
            }
          }))
        };
      }
    } else if (type === "reschedule") {
      return {
        mark_scheduled: true,
        segments_to_keep: [],
        new_segments: [
          {
            event: {
              dtend,
              dtstart,
              managementavailabilitysegment: {
                meld: {
                  in_house_servicers: assignees.map((a) => ({ agent: a }))
                }
              }
            }
          }
        ]
      };
    }
  } else if (assignees[0].type === "Vendor") {
    if (isReschedulingAppointment) {
      return {
        appointment: appointmentId,
        requestedSegment: {
          event: {
            dtend,
            dtstart
          }
        }
      };
    } else if (isAddingAppointment) {
      return {
        new_segments: [
          {
            event: {
              dtend,
              dtstart
            }
          }
        ]
      };
    } else if (!Features.isMultipleAppointmentsEnabled()) {
      return {
        mark_scheduled: true,
        segments_to_keep: [],
        new_segments: [
          {
            event: {
              dtend,
              dtstart
            },
            vendoravailabilitysegment: {
              assignment_request: {
                vendor: {
                  id: assignees[0].id
                }
              }
            }
          }
        ]
      };
    } else if (Features.isMultipleAppointmentsEnabled() && events && events.length > 0) {
      return {
        mark_scheduled: true,
        segments_to_keep: [],
        new_segments: [],
        multiple_segments_to_book: events.filter((event) => event.startTime).map((event) => ({
          event: {
            dtend: event.startTime.clone().add(event.endTime && event.endTime.diff(event.startTime, "minutes") || 0, "minutes").toISOString(),
            dtstart: event.startTime.toISOString()
          }
        }))
      };
    }
  }
  return null;
};
const getScheduleURL = (assignees, type, meld, isAddingAppointment = false, isReschedulingAppointment = false, appointmentId = null) => {
  if (assignees.length === 0) {
    return null;
  }
  if (assignees.every((a) => a.type === "ManagementAgent")) {
    if (isAddingAppointment) {
      return LinkHelper.normalize(ApiUrls.meldAddSegments(meld.id));
    }
    if (isReschedulingAppointment) {
      return LinkHelper.normalize(ApiUrls.meldRescheduleAppointmentSegment(meld.id));
    }
    if (type === "accept") {
      return LinkHelper.normalize(ApiUrls.managerScheduleMeld(meld.id));
    } else if (type === "reschedule") {
      return LinkHelper.normalize(ApiUrls.meldRescheduleSegments(meld.id));
    }
  } else if (assignees[0].type === "Vendor") {
    if (isReschedulingAppointment && appointmentId) {
      return LinkHelper.normalize(ApiUrls.meldRescheduleVendorAppointmentSegment(appointmentId));
    }
    if (isAddingAppointment) {
      return LinkHelper.normalize(ApiUrls.addNewVendorAppointment(meld.id));
    }
    return LinkHelper.normalize(ApiUrls.vendorUpdateSegments(meld.vendor_assignment_requests[0].id));
  }
  return null;
};
const getAssignees = (meld, allMaintenance) => {
  const assignedMaintenance = getAssignedMaint(meld);
  switch (assignedMaintenance == null ? void 0 : assignedMaintenance.type) {
    case "ManagementAgent":
      return assignedMaintenance.in_house_servicers.map(
        (servicer) => allMaintenance == null ? void 0 : allMaintenance.find((maint) => maint.type === "ManagementAgent" && maint.id === servicer.id)
      ).filter(Boolean);
    case "Vendor":
      return [assignedMaintenance.vendor].map((vendor) => allMaintenance == null ? void 0 : allMaintenance.find((maint) => maint.type === "Vendor" && maint.id === vendor.id)).filter(Boolean);
    default:
      return;
  }
};
const useScheduleMeld = (meld) => {
  const addToast = useAddToast();
  const queryClient = useQueryClient();
  const type = meld.started || isMeldScheduled(meld) ? "reschedule" : "accept";
  const { data: allMaintenance } = useGetAllMaintenance({ refetchOnMount: false });
  return useMutation({
    mutationKey: calendarMeldKeys.mutations.scheduleMutation((meld == null ? void 0 : meld.id.toString()) || "-"),
    mutationFn: ({
      startTime,
      durationInMin,
      events = [],
      isAddingAppointment = false,
      isReschedulingAppointment = false,
      appointmentId = null
    }) => {
      if (!allMaintenance) {
        return Promise.reject();
      }
      const assignees = getAssignees(meld, allMaintenance);
      if (!assignees) {
        return Promise.reject();
      }
      const data = formatEventDataToPatch(
        startTime,
        durationInMin,
        assignees,
        type,
        events,
        isAddingAppointment,
        isReschedulingAppointment,
        appointmentId
      );
      const url = getScheduleURL(assignees, type, meld, isAddingAppointment, isReschedulingAppointment, appointmentId);
      if (!data || !url) {
        return Promise.reject();
      }
      if (isAddingAppointment && assignees[0].type === "Vendor") {
        return apiPost(url, data);
      }
      return apiPatch(url, data);
    },
    onError: () => addToast({
      text: toastMessages.scheduleMeldFailure,
      color: "danger"
    }),
    onSuccess: (_, mutationProps) => {
      addToast({
        text: toastMessages.scheduleMeldSuccess,
        color: "success"
      });
      if (mutationProps.extraOnSuccess) {
        mutationProps.extraOnSuccess();
      }
      return Promise.all([
        queryClient.resetQueries(calendarMeldKeys.meldDetail(meld.id.toString())),
        queryClient.invalidateQueries(calendarMeldKeys.all())
      ]);
    }
  });
};
const usePatchAssignScheduleMeldMaintenance = (meld, personaCompositeId, typeOverride = void 0) => {
  const queryClient = useQueryClient();
  const addToast = useAddToast();
  const type = typeOverride || (meld.started || isMeldScheduled(meld) ? "reschedule" : "accept");
  const { data: allMaintenance } = useGetAllMaintenance({ refetchOnMount: false });
  const maintenance = allMaintenance == null ? void 0 : allMaintenance.find((m) => m.composite_id === personaCompositeId);
  return useMutation({
    mutationKey: calendarMeldKeys.mutations.assignAndSchedule(meld.id.toString()),
    mutationFn: (_0) => __async(void 0, [_0], function* ({
      startTime,
      durationInMin,
      events = [],
      isAddingAppointment = false,
      isReschedulingAppointment = false,
      appointmentId = null,
      extraOnSuccess
    }) {
      if (!maintenance) {
        return Promise.reject();
      }
      const data = formatEventDataToPatch(
        startTime,
        durationInMin,
        [maintenance],
        type,
        events,
        isAddingAppointment,
        isReschedulingAppointment,
        appointmentId
      );
      const url = getScheduleURL(
        [maintenance],
        type,
        meld,
        isAddingAppointment,
        isReschedulingAppointment,
        appointmentId
      );
      if (!data || !url) {
        return Promise.reject();
      }
      try {
        yield apiPatch(LinkHelper.normalize(ApiUrls.assignMaintenance(meld.id)), {
          user_groups: [],
          maintenance: [maintenance]
        });
        if (isAddingAppointment && maintenance.type === "Vendor") {
          yield apiPost(url, data);
        } else {
          yield apiPatch(url, data);
        }
        addToast({
          text: toastMessages.meldAssignedScheduleMeldSuccess,
          color: "success"
        });
        if (extraOnSuccess) {
          extraOnSuccess();
        }
        return Promise.all([
          queryClient.invalidateQueries(meldKeys.all),
          queryClient.resetQueries(calendarMeldKeys.meldDetail(meld.id.toString())),
          queryClient.invalidateQueries(calendarMeldKeys.all())
        ]);
      } catch (error) {
        try {
          yield apiPatch(LinkHelper.normalize(ApiUrls.assignMaintenance(meld.id)), {
            user_groups: [],
            maintenance: []
          });
        } catch (e) {
        }
        addToast({
          text: toastMessages.meldAssignedScheduleMeldFailure,
          color: "danger"
        });
        return;
      }
    })
  });
};
const useBookResidentAvailability = (meld) => {
  const addToast = useAddToast();
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: calendarMeldKeys.mutations.bookSegment(meld.id.toString()),
    mutationFn: ({ ids }) => {
      const firstAppt = ids[0];
      const payload = { ids };
      if (ids.length === 1) {
        return apiPatch(LinkHelper.normalize(ApiUrls.bookRequestedSegment(firstAppt)), {});
      } else {
        return apiPatch(LinkHelper.normalize(ApiUrls.bookRequestedSegment(firstAppt)), payload);
      }
    },
    onError: () => addToast({
      text: toastMessages.scheduleMeldFailure,
      color: "danger"
    }),
    onSuccess: () => {
      addToast({
        text: toastMessages.scheduleMeldSuccess,
        color: "success"
      });
      return Promise.all([
        queryClient.resetQueries(calendarMeldKeys.meldDetail(meld.id.toString())),
        queryClient.invalidateQueries(calendarMeldKeys.all())
      ]);
    }
  });
};
const useAddManagerAvailabilities = (meld) => {
  const addToast = useAddToast();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (props) => {
      const url = props.type === "accept" ? ApiUrls.managerScheduleMeld(meld.id) : ApiUrls.meldRescheduleSegments(meld.id);
      return apiPatch(LinkHelper.normalize(url), props.payload);
    },
    onSuccess: () => {
      addToast({ color: "success", text: toastMessages.calendarAddManagerAvailabilitiesSuccess });
      return Promise.all([
        queryClient.invalidateQueries(calendarMeldKeys.events()),
        queryClient.invalidateQueries(calendarMeldKeys.meldsListAll()),
        queryClient.resetQueries(calendarMeldKeys.meldDetail(meld.id.toString()))
      ]);
    },
    onError: () => addToast({ color: "danger", text: toastMessages.calendarAddManagerAvailabilitiesFailure })
  });
};
export {
  useGetCalendarMeldsList,
  useGetCalendarMeldDetails,
  usePatchAssignScheduleMeldMaintenance,
  useGetUnscheduledMelds,
  useGetManagementScheduledEvents,
  useGetManagementGoogleCalendarEvents,
  useGetVendorScheduledEvents,
  calendarMeldKeys,
  useScheduleMeld,
  useBookResidentAvailability,
  useGetCalendarManagerEventDetails,
  useAddManagerAvailabilities
};
