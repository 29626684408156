import { LinkHelper } from "@pm-shared/utils/link";
import { RouteUrls } from "./route-urls";
export function getFullAddressWithPropertyNameArray(unit) {
  return [
    getPropertyNameDistinctFromLine1(unit),
    getDisplayStreetAndUnit(unit),
    unit.display_address.line_2,
    unit.display_address.line_3,
    getFormattedCityStateZip(unit)
  ].filter(Boolean);
}
export function getPropertyNameDistinctFromLine1(unit) {
  var _a;
  if (((_a = unit.prop) == null ? void 0 : _a.property_name) && unit.prop.property_name !== unit.prop.line_1) {
    return unit.prop.property_name;
  }
  if (unit.display_address.property_name === unit.display_address.line_1) {
    return "";
  }
  return unit.display_address.property_name || "";
}
export function getDisplayStreetAndUnit(unit) {
  return [unit.display_address.line_1, getFormattedUnit(unit)].join(", ").trim();
}
export function getFormattedUnit(unit) {
  return [
    { prefix: "UNIT", number: unit.unit },
    { prefix: "RM", number: unit.room },
    { prefix: "DEPT", number: unit.department }
  ].filter((designator) => {
    return !!designator.number;
  }).map((designator) => {
    return [designator.prefix, designator.number].join(" ");
  }).join(", ") || "";
}
export function getFormattedCityStateZip(unit) {
  if (unit.display_address.city) {
    return `${unit.display_address.city}, ${unit.display_address.county_province} ${unit.display_address.postcode}`;
  }
  return `${unit.display_address.county_province} ${unit.display_address.postcode}`;
}
export function getTwoOrThreeLineUnitNameAddress(unit) {
  const addressLine = [unit.display_address.line_1, unit.display_address.line_2, unit.display_address.line_3].filter(Boolean).join(", ");
  return [getPropertyNameDistinctFromLine1(unit), addressLine, getFormattedCityStateZip(unit)].filter(Boolean).join("\n");
}
export const getUnitDetailsLink = (unitId, tab) => {
  if (!tab) {
    return LinkHelper.normalize(RouteUrls.unitDetail({ id: unitId }));
  } else if (tab === "invoices") {
    return LinkHelper.normalize(RouteUrls.unitDetailInvoices({ id: unitId }));
  } else if (tab === "contacts") {
    return LinkHelper.normalize(RouteUrls.unitDetailContacts({ id: unitId }));
  } else if (tab === "edit") {
    return LinkHelper.normalize(RouteUrls.unitDetailEdit({ id: unitId }));
  }
  return LinkHelper.normalize(RouteUrls.unitDetail({ id: unitId }));
};
