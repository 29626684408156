import React from "react";
import { EuiAvatar } from "@elastic/eui";
import { getPersonaColor } from "@pm-frontend/shared/utils/color-utils";
import { colors } from "@pm-frontend/styles";
const PmAvatar = (props) => {
  return /* @__PURE__ */ React.createElement(
    EuiAvatar,
    {
      className: "pm-header-avatar",
      name: props.name,
      color: props.persona ? getPersonaColor(props.persona) : props.color,
      style: {
        height: "28px",
        width: "28px",
        fontFamily: '"Open Sans", Helvetica, Roboto, Arial, sans-serif',
        color: colors.neutrals.gray900
      }
    }
  );
};
export { PmAvatar };
