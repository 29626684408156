import { LinkHelper } from "@pm-shared/utils/link";
import { INTEGRATIONS, RentManagerModelSyncStatus } from "../types/integrations";
import { ApiUrls } from "./api-urls";
import { RouteUrls } from "./route-urls";
export const getVendorDetailsLink = ({ id }) => LinkHelper.normalize(RouteUrls.vendorDetail(id));
export const getAssignedPropertyGroups = (propertyGroups, excludedPropertyGroups) => {
  const excludedPropertyGroupsMap = {};
  excludedPropertyGroups.forEach((pg) => {
    excludedPropertyGroupsMap[pg.id] = true;
  });
  return propertyGroups.filter((group) => !excludedPropertyGroupsMap[group.id]);
};
export const getAssignedPropertyGroupsByNumber = (propertyGroups, excludedPropertyGroups) => {
  const excludedPropertyGroupsMap = {};
  excludedPropertyGroups.forEach((pg) => {
    excludedPropertyGroupsMap[pg] = true;
  });
  return propertyGroups.filter((group) => !excludedPropertyGroupsMap[group]);
};
export const getVendorIntegrationDetails = (vendor) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m;
  const result = {
    integrationName: void 0,
    integrationResourceName: void 0,
    resourceStatus: void 0,
    deletedStatus: false
  };
  if (vendor.appfolio_linked) {
    const afApiVendorInfo = (_c = (_b = (_a = vendor.vendor_management) == null ? void 0 : _a.appfolio_vendor_link) == null ? void 0 : _b.af_vendor) == null ? void 0 : _c.vendor;
    const afApiVendorName = (afApiVendorInfo == null ? void 0 : afApiVendorInfo.CompanyName) || [afApiVendorInfo == null ? void 0 : afApiVendorInfo.FirstName, afApiVendorInfo == null ? void 0 : afApiVendorInfo.LastName].filter(Boolean).join(" ");
    result.integrationName = INTEGRATIONS.APPFOLIO;
    result.integrationResourceName = afApiVendorName;
    result.resourceStatus = vendor.appfolio_linked ? "active" : "deactivated";
  } else if (vendor.buildium_linked) {
    const buildiumVendorInfo = (_f = (_e = (_d = vendor.vendor_management) == null ? void 0 : _d.buildium_vendor_link) == null ? void 0 : _e.bldm_vendor) == null ? void 0 : _f.vendor;
    const name = (buildiumVendorInfo == null ? void 0 : buildiumVendorInfo.CompanyName) || [buildiumVendorInfo == null ? void 0 : buildiumVendorInfo.FirstName, buildiumVendorInfo == null ? void 0 : buildiumVendorInfo.LastName].filter(Boolean).join(" ");
    result.integrationName = INTEGRATIONS.BUILDIUM;
    result.integrationResourceName = name;
    result.resourceStatus = vendor.buildium_linked ? "active" : "inactive";
  } else if ((_g = vendor.vendor_management) == null ? void 0 : _g.propertyware_vendor) {
    const pwVendorInfo = (_h = vendor.vendor_management) == null ? void 0 : _h.propertyware_vendor;
    result.integrationName = INTEGRATIONS.PROPERTYWARE;
    result.integrationResourceName = (_j = (_i = pwVendorInfo == null ? void 0 : pwVendorInfo.pw_vendor) == null ? void 0 : _i.vendor) == null ? void 0 : _j.name;
    result.resourceStatus = pwVendorInfo ? "active" : "inactive";
  } else if (vendor.rent_manager_linked) {
    const rmVendorInfo = (_k = vendor.vendor_management) == null ? void 0 : _k.rent_manager_vendor;
    result.integrationName = INTEGRATIONS.RENTMANAGER;
    result.integrationResourceName = (_m = (_l = rmVendorInfo == null ? void 0 : rmVendorInfo.rm_vendor) == null ? void 0 : _l.vendor) == null ? void 0 : _m.Name;
    result.resourceStatus = "active";
  }
  return result;
};
export const getUnlinkVendorUrl = (vendor) => {
  var _a, _b, _c, _d, _e, _f, _g, _h;
  const integrationDetails = getVendorIntegrationDetails(vendor);
  switch (integrationDetails.integrationName) {
    case INTEGRATIONS.APPFOLIO:
      let afapiVendorId = (_b = (_a = vendor.vendor_management) == null ? void 0 : _a.appfolio_vendor_link) == null ? void 0 : _b.id;
      return afapiVendorId ? ApiUrls.afapiVendor(afapiVendorId) : void 0;
    case INTEGRATIONS.BUILDIUM:
      let bldmVendorId = (_d = (_c = vendor.vendor_management) == null ? void 0 : _c.buildium_vendor_link) == null ? void 0 : _d.id;
      return bldmVendorId ? ApiUrls.bldmVendor(bldmVendorId) : void 0;
    case INTEGRATIONS.PROPERTYWARE:
      let pwVendorId = (_f = (_e = vendor.vendor_management) == null ? void 0 : _e.propertyware_vendor) == null ? void 0 : _f.id;
      return pwVendorId ? ApiUrls.pwVendorsSyncUpdate(pwVendorId) : void 0;
    case INTEGRATIONS.RENTMANAGER:
      let rmVendorId = (_h = (_g = vendor.vendor_management) == null ? void 0 : _g.rent_manager_vendor) == null ? void 0 : _h.id;
      return rmVendorId ? ApiUrls.rmVendorsSyncUpdate(rmVendorId) : void 0;
    default:
      return;
  }
};
export const getLinkVendorUrl = (integration) => {
  switch (integration) {
    case INTEGRATIONS.APPFOLIO:
      return ApiUrls.afapiVendorSync;
    case INTEGRATIONS.BUILDIUM:
      return ApiUrls.bldmVendorSync;
    case INTEGRATIONS.PROPERTYWARE:
      return ApiUrls.pwVendorsSync;
    case INTEGRATIONS.RENTMANAGER:
      return ApiUrls.rmVendorsSync;
    default:
      return "";
  }
};
export const getLinkVendorPayload = (vendor, activeIntegration, partnerId) => {
  switch (activeIntegration) {
    case INTEGRATIONS.APPFOLIO:
      return {
        vendor: vendor.id,
        af_vendor: partnerId
      };
    case INTEGRATIONS.BUILDIUM:
      return {
        vendor: vendor.id,
        buildium_vendor: partnerId
      };
    case INTEGRATIONS.PROPERTYWARE:
      return {
        vendor: vendor.id,
        pw_vendor: partnerId
      };
    case INTEGRATIONS.RENTMANAGER:
      return {
        vendor: vendor.id,
        rm_vendor: partnerId,
        sync_status: RentManagerModelSyncStatus.LINK
      };
  }
};
export const getIsVendorLinked = (vendor) => {
  var _a;
  return vendor.appfolio_linked || vendor.buildium_linked || vendor.rent_manager_linked || !!((_a = vendor.vendor_management) == null ? void 0 : _a.propertyware_vendor);
};
export const getVendorDisplayName = (vendor) => {
  if (vendor.name) {
    return vendor.name;
  } else if (vendor.email) {
    return vendor.email;
  } else {
    return "A vendor";
  }
};
