import { initialize as LDInit } from "launchdarkly-js-client-sdk";
let client;
let isReady = false;
const Features = {
  isInjected: false,
  isMDSMeldListEnabled() {
    return variation("features.mds-meld-list", false);
  },
  isNewUnitListEnabled() {
    return variation("features.new-unit-list-view", false);
  },
  isEstimatesListViewEnabled() {
    return variation("features.estimates", false);
  },
  isBehaviorsEnabled() {
    return variation("features.behaviors", false);
  },
  isOwnerServicesEnabled() {
    return variation("features.owner-services", false);
  },
  isWhiteLabelingEnabled() {
    return variation("features.white-labeling", false);
  },
  isPageBannerEnabled() {
    return variation("features.page-banner", false);
  },
  isBillingPortalEnabled() {
    return variation("features.billing-portal", false);
  },
  isLegacyCustomer() {
    return variation("entitlements.legacy-customers", false);
  },
  isWorkflowUpsellEnabled() {
    return variation("features.workflow-upsells", false);
  },
  isPermissionToEnterEnabled() {
    return variation("features.permission-to-enter", false);
  },
  isBusinessHoursExperimentEnabled() {
    return variation("experiments.business-hours", false);
  },
  isBuildiumOnboardingProcess() {
    return variation("features.buildium-integration.onboarding", "MELD_EXISTS");
  },
  isPropertyNameEnabled() {
    return variation("features.property-name", false);
  },
  isUIRedesignEnabledProduction() {
    return variation("features.ui-redesign-2023", false) === "PRODUCTION";
  },
  isUIRedesignEnabledBeta() {
    return variation("features.ui-redesign-2023", false) === "BETA";
  },
  isUIRedesignEnabledDevelopment() {
    return variation("features.ui-redesign-2023", false) === "DEVELOPMENT";
  },
  isUIRedesignEnabledDevelopmentOrProduction() {
    return Features.isUIRedesignEnabledProduction() || Features.isUIRedesignEnabledDevelopment();
  },
  isUIRedesignSunset() {
    return variation("features.ui-redesign-2023-sunset", false);
  },
  isUIRedesignScheduler() {
    return variation("features.ui-redesign-scheduler", false);
  },
  isInRecurringRangeEnabled() {
    return variation("features.workflow.inrecurringrange", false);
  },
  isActionableInsightsEnabled() {
    return variation("features.actionable-insight.multi", "NO_INSIGHTS");
  },
  isGptSummaryEnabled() {
    return variation("GPT.chat-summary", false);
  },
  isGptTranslateEnabled() {
    return variation("GPT.translate", false);
  },
  isResidentGptTranslateEnabled() {
    return variation("GPT.translate-residents", false);
  },
  isGptChatModifyEnabled() {
    return variation("GPT.chat-modify", false);
  },
  isIntegrationLogsEnabled() {
    return variation("Integration-Logs.manager-view", false);
  },
  isEnterpriseHubEnabled() {
    return variation("features.enterprise-hub", false);
  },
  isNotificationNoiseEnabled() {
    return variation("features.notification-noise", false);
  },
  isNearbyMeldsEnabled() {
    return variation("features.nearby-melds", false);
  },
  isQuickbookConnectEnabled() {
    return variation("features.quickbooks-connect", false);
  },
  isMergeMeldEnabled() {
    return variation("features.megre-melds", false);
  },
  isMeldMentionsEnabled() {
    return variation("features.meld-mentions", false);
  },
  isWorkLogBannerEnabled() {
    return variation("features.work-log-banner", false);
  },
  isGoogleCalendarWriteIntegrationEnabled() {
    return variation("features.google-calendar-integration", false);
  },
  isGoogleCalendarReadIntegrationEnabled() {
    return variation("google-calendar-read-integration", false);
  },
  // used to tune app performance via feature flag if we need to
  isRefetchOnWindowFocusEnabled() {
    return variation("performance-tuning-refetch-on-window-focus", false);
  },
  // used to tune app performance via feature flag if we need to
  getQueryStaleTime() {
    return variation("performance-tuning-query-stale-time", 0);
  },
  isVendorSearchEnabled() {
    return variation("features.nexus-vendor-search", false);
  },
  isTitleModalEnabled() {
    return variation("features.title-modal", false);
  },
  isMultipleAppointmentsEnabled() {
    return variation("multiple-appointments", false);
  },
  isNexusHubEnabled() {
    return variation("features.nexus-hub", false);
  },
  isNexusIntegrationsEnabled() {
    return variation("features.nexus-integrations", false);
  },
  isSmartAssignmentEnabled() {
    return variation("features.smart-assignment", false);
  },
  isReportRedesignEnabled() {
    return variation("features.reports-redesign", false);
  },
  isRecommendAPIEnabled() {
    return variation("features.recommend-api", false);
  }
};
export class FeatureFlagClientHelper {
  static onClientReady(fn) {
    client == null ? void 0 : client.on("ready", fn);
  }
  static onClientChange(fn) {
    client == null ? void 0 : client.on("change", fn);
  }
  static get isClientReady() {
    return isReady;
  }
}
export function initialize() {
  const featureFlagData = window.PM.featureFlagBootstrap;
  if (featureFlagData.bootstrapData.USE_DUMMY_DATA) {
    client = new DummyClient(featureFlagData.bootstrapData);
    isReady = true;
  } else {
    client = LDInit(featureFlagData.clientId, featureFlagData.user, {
      bootstrap: featureFlagData.bootstrapData,
      sendEvents: false
    });
    client.on("ready", () => {
      isReady = true;
    });
    client.on("change", () => {
      console.log("Feature flags changed");
    });
  }
}
function variation(variationName, defaultValue = false) {
  if (client && isReady) {
    return client.variation(variationName, defaultValue);
  }
  return defaultValue;
}
class DummyClient {
  constructor(flags) {
    this.flags = flags;
    isReady = true;
  }
  variation(variationName, defaultValue) {
    return this.flags[variationName] || defaultValue;
  }
  on(_event, _funct) {
    return;
  }
}
export default Features;
