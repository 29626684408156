import randomColor from "randomcolor";
export const getAccessibleTextColor = (hexColor) => {
  hexColor = hexColor.replace("#", "");
  const r = parseInt(hexColor.substring(0, 2), 16);
  const g = parseInt(hexColor.substring(2, 4), 16);
  const b = parseInt(hexColor.substring(4, 6), 16);
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  return luminance > 0.5 ? "#000000" : "#FFFFFF";
};
class PersonaColorCache {
  constructor() {
    this.cache = {};
  }
  getColor(seed) {
    if (this.cache[seed]) {
      return this.cache[seed];
    } else {
      const color = randomColor({ seed, luminosity: "light" });
      this.cache[seed] = color;
      return color;
    }
  }
}
const colorCache = new PersonaColorCache();
export const getPersonaColor = (user) => {
  return colorCache.getColor(user.created);
};
