const { format, parseISO } = require("date-fns");
const getDate = (date) => {
  let dateObject;
  if (date instanceof Date) {
    dateObject = date;
  } else {
    dateObject = new Date(date);
  }
  return dateObject;
};
const formatTimeHHMM_xm = (date, emptyMsg = "") => {
  const dateObject = getDate(date);
  if (dateObject.toString() === "Invalid Date") {
    return emptyMsg;
  }
  return format(dateObject, "h:mm aaa");
};
const formatDateTimeMonthDDYYYYHHMM_xm = (date, emptyMessage = "") => {
  try {
    const dateObject = parseISO(date);
    return format(dateObject, "MMMM d, yyyy, h:mm aaa");
  } catch (e) {
    return emptyMessage;
  }
};
const formatFutureEventDateStart = (eventData) => {
  if (eventData && eventData.dtstart) {
    const eventStart = new Date(eventData.dtstart);
    const now = /* @__PURE__ */ new Date();
    if (eventStart >= now) {
      return formatDateTimeMonthDDYYYYHHMM_xm(eventData.dtstart);
    }
  }
  return null;
};
const formatFutureEventDateEnd = (eventData) => {
  if (eventData && eventData.dtend) {
    const eventEnd = new Date(eventData.dtend);
    const now = /* @__PURE__ */ new Date();
    if (eventEnd >= now) {
      return formatTimeHHMM_xm(eventData.dtend);
    }
  }
  return null;
};
const formatPastEventDateStart = (eventData) => {
  if (eventData && eventData.dtstart) {
    const eventStart = new Date(eventData.dtstart);
    const now = /* @__PURE__ */ new Date();
    if (eventStart < now) {
      return formatDateTimeMonthDDYYYYHHMM_xm(eventData.dtstart);
    }
  }
  return null;
};
const formatPastEventDateEnd = (eventData) => {
  if (eventData && eventData.dtend) {
    const eventEnd = new Date(eventData.dtend);
    const now = /* @__PURE__ */ new Date();
    if (eventEnd < now) {
      return formatTimeHHMM_xm(eventData.dtend);
    }
  }
  return null;
};
const allEventDateData = (eventData) => {
  if (eventData && eventData.dtstart && eventData.dtend) {
    const startTime = formatDateTimeMonthDDYYYYHHMM_xm(eventData.dtstart);
    const endTime = formatTimeHHMM_xm(eventData.dtend);
    const result = `${startTime} - ${endTime}`;
    return result;
  }
  return null;
};
const formatDateMonthDDYYYY = (date, emptyMsg = "") => {
  const dateObject = getDate(date);
  if (dateObject.toString() === "Invalid Date") {
    return emptyMsg;
  }
  return format(dateObject, "LLLL do, yyyy");
};
const formatDateMMDDYYYY = (date, emptyMsg = "") => {
  const dateObject = getDate(date);
  if (dateObject.toString() === "Invalid Date") {
    return emptyMsg;
  }
  return dateObject.toLocaleDateString("en-us");
};
const formatDateTimeMMDDYYYYHHMM_xm = (date, emptyMessage = "") => {
  const dateObject = getDate(date);
  if (dateObject.toString() === "Invalid Date") {
    return emptyMessage;
  }
  return format(dateObject, "MM/d/yyyy, h:mm aaa");
};
export {
  formatTimeHHMM_xm,
  formatDateTimeMonthDDYYYYHHMM_xm,
  formatDateMonthDDYYYY,
  formatDateMMDDYYYY,
  formatDateTimeMMDDYYYYHHMM_xm,
  formatFutureEventDateStart,
  formatFutureEventDateEnd,
  formatPastEventDateEnd,
  formatPastEventDateStart,
  allEventDateData
};
